<template>
  <div class="Carsharing-personal">
    <MuiValidationWrapper @form-validation="submitReservation">
      <div
        v-if="showUpgradeInsurance"
        class="Carsharing-personal__item"
      >
        <h2 class="Carsharing-personal__item__title">
          {{ $t('carsharing_personal.title_1') }}
        </h2>
        <InsuranceSelectorComponent
          v-if="bookingSummary"
          :data="bookingSummary.insurance"
          :is-disabled="isFormDisabled"
          class="Carsharing-personal__item__content Carsharing-personal__item__content--highlighted"
          @changed="onChangeInsurance"
          @show-insurance-details="showInsuranceDetails"
        />
      </div>

      <div
        v-if="isBookingOnBehalf"
        class="mb-3"
      >
        <h2 class="Carsharing-personal__item__title">
          {{ $t('carsharing_personal.booking_on_behalf_title') }}:
        </h2>
        <p>
          {{ `${get(reservation, 'driver.user_first_name')} ${get(reservation, 'driver.user_last_name')}` }}
        </p>
      </div>

      <div
        v-if="isOneWayCompatible"
        class="Carsharing-personal__item"
      >
        <h2 class="Carsharing-personal__item__title">
          {{ $t('Carsharing.views.Booking.Location.origin_text') }}
        </h2>
        <p class="mb-3">
          <ui-icon
            :size="ICONS_SIZES.small"
            :icon="ICONS.location"
          />
          {{ get(reservation, 'pickUpLocation.name') }}
        </p>

        <OneWaySelectorComponent
          :items="filteredOneWayLocations"
          :value="get(reservation, 'dropOffLocation.uuid')"
          :is-disabled="isFormDisabled"
          @location="location => setBooking({ dropOffLocation: location })"
        />
      </div>

      <div
        v-if="showAdditionalDrivers"
        class="Carsharing-personal__item"
        data-test-id="additional_drivers-container"
      >
        <h6 class="Carsharing-personal__item__title">
          {{ $t('carsharing_personal.additional_drivers_title') }}
        </h6>

        <AdditionalDriversComponent
          ref="additionalDriverInput"
          :selected-drivers="map(additionalDriversFiltered, 'data')"
          :disabled="isAdditionalVehicleUserDisabled || isFormDisabled"
          :has-invalid-drivers="size(invalidVehicleUserDrivers) > 0"
          :booking-owner-uuid="get(reservation, 'driver.user_uuid')"
          class="mb-3"
          @add-driver="onAddDriver"
        />

        <RemovableBadgeComponent
          v-for="(driver, index) in additionalDriversFiltered"
          :key="`driver_added-${index}`"
          :color="getDriverBadgeColor(invalidVehicleUserDrivers, driver.data)"
          :data-test-id="`added_driver_${index}`"
          :on-remove="() => onRemoveDriver(driver)"
          :editable="!isAdditionalDriverBadgesDisabled"
          class="mb-2 mr-2"
        >
          {{ driver.data }}
        </RemovableBadgeComponent>
        <div class="d-flex flex-column">
          <ui-alert
            v-for="(invalidDrivers, reasonKey) in invalidAdditionalDriversGrouped"
            :key="`invalidDriver-${reasonKey}`"
            :color="COLORS.danger"
            :icon="ICONS.alertAloneFull"
            class="py-2"
          >
            <span class="ml-2 emobg-font-small emobg-font-weight-normal">
              {{ $t(`api.errors.${reasonKey}`) }}
              <br>
              {{ map(invalidDrivers, 'email').join(', ') }}
            </span>
          </ui-alert>
        </div>
      </div>

      <div
        v-if="showAdditionalPassengers"
        class="Carsharing-personal__item"
        data-test-id="additional_passengers-container"
      >
        <h6 class="Carsharing-personal__item__title">
          {{ $t('carsharing_personal.additional_passengers_title', { free_seats: freeSeats }) }}
        </h6>

        <AdditionalPassengersComponent
          :disabled="isAdditionalVehicleUserDisabled || isFormDisabled"
          @add-passenger="onAddPassenger"
        />

        <div class="mt-4">
          <RemovableBadgeComponent
            v-for="(passenger, index) in additionalPassengersFiltered"
            :key="`passenger_added-${index}`"
            :color="webAppColors.informative"
            :data-test-id="`passenger_added_${index}`"
            :on-remove="() => onRemovePassenger(passenger)"
            :editable="!isFormDisabled"
            class="mr-2"
          >
            {{ passenger.data }}
          </RemovableBadgeComponent>
        </div>
      </div>

      <div class="Carsharing-personal__item">
        <div v-if="isBookingBehalfAndEmployeePays">
          {{ $t('carsharing_personal.pays_employee') }}
        </div>
        <slot v-else />
      </div>

      <div v-if="canUseCostAllocation">
        <ui-skeleton
          v-if="areCostAllocationsLoading"
          height="83"
        />
        <div v-else>
          <div
            v-if="hasCostAllocations"
            data-test-id="cost_allocation-container"
          >
            <h6 class="Carsharing-personal__item__title">
              {{ $t('carsharing_personal.booking_properties_title') }}
            </h6>

            <CostAllocation
              :costs-list="costs"
              :selected-costs="bookingCostAllocations"
              :is-disabled="isFormDisabled"
              @update:cost="updateBookingCost"
              @on:form-status="(value) => isCostAllocationComponentReady = value"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isBusinessProfileActive"
        class="my-4"
      >
        <h6 class="Carsharing-personal__item__title">
          {{ $t('carsharing_personal.title_5') }}
        </h6>
        <ui-text-area
          :value="businessComment"
          name="observations"
          :placeholder="$t('carsharing_personal.observations_placeholder')"
          :disabled="isFormDisabled"
          class="w-100"
          rows="5"
          data-test-id="business_comment-textarea"
          @changevalue="event => businessComment = event.detail"
        />
      </div>

      <div
        v-if="showCarpooling"
        class="CarpoolingSelector Carsharing-personal__item"
      >
        <div class="Carsharing-personal__item__content Carsharing-personal__item__content--highlighted">
          <p class="d-flex Carsharing-personal__item__carpooling__title mb-2">
            <ui-checkbox
              :checked="useCarpooling"
              :caption="$t('carsharing_personal.carpooling_label1')"
              :disabled="isAdditionalVehicleUserDisabled || isFormDisabled"
              :size="SIZES.small"
              class="ml-0"
              @changevalue="({ detail }) => useCarpooling = toBoolean(detail)"
            />
          </p>
          <p class="mb-0">
            <label
              for="carpooling"
              class="mb-0"
            >
              {{ $t('carsharing_personal.carpooling_label2') }}
            </label>
          </p>
        </div>
      </div>

      <div id="adyen-action" />

      <PaymentSummaryComponent
        v-if="bookingSummary"
        :start="get(bookingSummary, 'booking.start')"
        :end="get(bookingSummary, 'booking.end')"
        :show-prices="!get(bookingSummary, 'is_unpriced_tariff')"
        :prices="get(bookingSummary, 'price')"
        :is-electric="!!get(bookingSummary, 'vehicle.electric_details')"
        :insurance="upgradedInsurance"
        :total-mileage-included="isLongDistance(get(bookingSummary, 'booking.booking_type'))"
        :is-outside-working-hours="isOutsideWorkingHours"
        :tariff="get(reservation, 'vehicle.tariff')"
        class="mb-5"
      />

      <p class="emobg-font-small emobg-font-line-height-large emobg-color-ink-light">
        {{ $t('carsharing_personal.terms_and_conditions_applied', { operator: appName }) }}
      </p>

      <ui-button
        v-bind="fetchButtonSpecs()"
        data-test-id="confirm_booking"
        :type="BUTTON_TYPES.submit"
        :size="SIZES.large"
        :disabled="isButtonDisabled"
        :loading="loading || isValidating"
      >
        {{ buttonText }}
      </ui-button>
    </MuiValidationWrapper>
    <FeedbackModalComponent
      v-model="feedbackModal.isOpen"
      v-bind="feedbackModal"
    />
  </div>
</template>

<script>
// Libraries
import {
  mapActions, mapGetters, mapMutations, mapState,
} from 'vuex';
import isBoolean from 'lodash/isBoolean';
import get from 'lodash/get';
import reject from 'lodash/reject';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import size from 'lodash/size';
import reduce from 'lodash/reduce';
import find from 'lodash/find';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import concat from 'lodash/concat';

import { DATE_FORMAT, toBoolean } from '@emobg/web-utils';
import { MuiValidationWrapper } from '@emobg/motion-ui/v1';
import { BUTTON_TYPES } from '@emobg/web-components';
import config from '@/config';
import { scrollTo } from '@/utils/scrollTo';
import { isLongDistance } from '@/helpers/booking/bookingHelpers';
// Components
import InsuranceSelectorComponent from '@/vue/components/molecules/common/InsuranceSelector/InsuranceSelectorComponent';
// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
// Constants
import { SEGMENT_EVENTS, SEGMENT_PARAM_NAMES } from '@/vue/constants';

// Routes
import BookingRoutesNames from '@Bookings/router/routes-names';
import accountSettingsRouteNames from '@Account/Settings/router/routes-names';
import { algoliaFilterForAdditionalDrivers } from '@/utils/algoliaHelpers';

import * as BookingCostAllocationModule from '@/stores/CostAllocation/Booking/BookingCostAllocationModule';

import { ADDITIONAL_DRIVERS_FILTER, ADDITIONAL_PASSENGERS_FILTER } from '@/constants/vehicleUserTypes.const';

import { COMPONENT_EVENTS } from '@/constants/componentEvents.const';

import AdditionalDriversComponent from '@/components/AdditionalDrivers/AdditionalDriversComponent';
import AdditionalPassengersComponent from '@/components/AdditionalPassengers/AdditionalPassengersComponent';
import RemovableBadgeComponent from '@/components/RemovableBadge/RemovableBadgeComponent';

import * as CostAllocationDataModule from '@/stores/CostAllocation/DataModule/CostAllocationDataModule';
import { NAMESPACE as OperatorLocationsNamespace } from '@/stores/OperatorLocations/OperatorLocationsModule';

import OneWaySelectorComponent from '@/components/OneWaySelector/OneWaySelectorComponent';

import * as BookingModule from '@/vue/stores/Booking/BookingStore';
import {
  ACTIONS as VehicleUserActions,
  GETTERS as VehicleUserGetters,
  MUTATIONS as VehicleUserMutations,
  NAMESPACE as VehicleUserNamespace,
} from '@/stores/VehicleUser/VehicleUserModule';
import { getUserDrivingLicense } from '@/stores/User/UserMapper';
import { companyAllowsAdditionalDriver, companyUuid } from '@/stores/Company/CompanyMapper';

import * as CostAllocationModule from '@/vue/stores/BookingSummary/CostAllocations';
import { addCleanedCost } from '@/helpers/userCompany/costAllocationsHelpers';
import { getRequestCode } from '@/helpers/costAllocation/costAllocationHelpers';

import PaymentSummaryComponent from '@/components/BookingForm/PaymentSummary/PaymentSummaryComponent';
import { genericUserBlockArgs } from '@/constants/defaultModalArgs';
import { getDriverBadgeColor } from '@/components/AdditionalDrivers/helpers/badgeHelpers';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';

import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { companyAllowsCarpooling } from '@/stores/User/UserData/UserDataMapper';

import { useSegment } from '@/composable/Segment/segment';
import { useTheme } from '@/composable/Theme/useTheme';

import { canUseCostAllocation, currentProfile, isBusinessProfileActive } from '@/stores/User/Profile/ProfileMapper';
import { webAppColors } from '@/constants/themes';
import CostAllocation from './CostAllocation';

export default {
  name: 'BookingSummaryFormComponent',

  components: {
    AdditionalDriversComponent,
    AdditionalPassengersComponent,
    CostAllocation,
    InsuranceSelectorComponent,
    MuiValidationWrapper,
    OneWaySelectorComponent,
    PaymentSummaryComponent,
    RemovableBadgeComponent,
    FeedbackModalComponent,
  },

  mixins: [
    EventHandlerMixin,
  ],

  props: {
    isConfirmDisabled: {
      type: Boolean,
      default: false,
    },
    isPaymentReady: {
      type: Boolean,
      default: true,
    },
    isRecurringBooking: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    areCostAllocationsLoading: {
      type: Boolean,
      default: false,
    },
    bookingUserUuid: {
      type: String,
      default: '',
    },
  },

  setup() {
    const { notifyError } = useNotifications();
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();
    return {
      notifyError,
      trackSegment,
      fetchButtonSpecs,
      webAppColors,
    };
  },

  data() {
    return {
      form: {},
      isValidating: false,
      isCostAllocationComponentReady: { areAllValidated: false },
      feedbackModal: {
        isOpen: true,
      },
    };
  },

  computed: {
    getUserDrivingLicense,
    isBusinessProfileActive,
    canUseCostAllocation,
    currentProfile,
    companyAllowsCarpooling,
    companyAllowsAdditionalDriver,
    companyUuid,

    ...mapState(CostAllocationModule.nameSpace, {
      costs: state => get(state, 'cost.data.data'),
    }),

    // TODO: Temp fix, use the same store: https://europcarmobility.atlassian.net/browse/CBF-882
    ...mapState(
      BookingCostAllocationModule.NAMESPACE,
      {
        currentCostAllocations: state => state.COST_ALLOCATION_DATA.currentCostAllocations,
      },
    ),

    ...mapGetters(OperatorLocationsNamespace, ['oneWayLocations']),

    ...mapState(VehicleUserNamespace, {
      invalidVehicleUserDrivers: state => get(state, 'invalidDrivers', []),
    }),

    ...mapState(BookingModule.nameSpace, ['reservation', 'bookingSummary']),

    ...mapGetters(
      VehicleUserNamespace,
      {
        getVehicleUser: VehicleUserGetters.data,
        getVehicleUserCount: VehicleUserGetters.count,
        hasVehicleUserDriver: VehicleUserGetters.hasDriver,
      },
    ),

    ...mapGetters(BookingModule.nameSpace, ['isOutsideWorkingHours', 'isBookingOnBehalf']),

    filteredOneWayLocations() {
      const pickUpUuid = get(this, 'reservation.pickUpLocation.uuid');
      const sanitizeOneWayLocations = reject(this.oneWayLocations, ['uuid', pickUpUuid]);

      return map(sanitizeOneWayLocations, ({ uuid, name }) => ({ value: uuid, label: name }));
    },

    invalidAdditionalDriversGrouped() {
      return groupBy(this.invalidVehicleUserDrivers, 'reason');
    },

    isButtonDisabled() {
      return (this.hasCostAllocations && !this.isCostAllocationComponentReady.areAllValidated)
        || !this.isPaymentReady;
    },

    additionalPassengersFiltered() {
      return this.getVehicleUser(this.ADDITIONAL_PASSENGERS_FILTER);
    },

    additionalDriversFiltered() {
      return this.getVehicleUser(this.ADDITIONAL_DRIVERS_FILTER);
    },

    additionalPassengersCount() {
      return this.getVehicleUserCount(this.ADDITIONAL_PASSENGERS_FILTER);
    },

    additionalDriversCount() {
      return this.getVehicleUserCount(this.ADDITIONAL_DRIVERS_FILTER);
    },

    bookingCostAllocations: {
      get() {
        return this.currentCostAllocations;
      },
      set(value) {
        this.setCurrentCostAllocations(value);
      },
    },

    bookingEnd() {
      return moment(get(this, 'reservation.end', null));
    },

    showPaymentMethod() {
      const companyPays = get(this, 'bookingSummary.company.company_pays');

      if (isEmpty(this.bookingSummary) || companyPays) {
        return false;
      }

      return !get(this, 'bookingSummary.is_unpriced_tariff');
    },
    showUpgradeInsurance() {
      const isUpsellInsurance = get(this, 'bookingSummary.insurance.upsell_possible');

      return !get(this, 'bookingSummary.is_unpriced_tariff') && isUpsellInsurance;
    },
    showCarpooling() {
      const isOpenLocation = get(this, 'reservation.location.isOpen');
      const canUseCarpooling = this.isBusinessProfileActive
        && this.companyAllowsCarpooling;

      return !isOpenLocation && canUseCarpooling && !this.isRecurringBooking;
    },
    hasCostAllocations() {
      return isArray(this.costs) && size(this.costs);
    },
    isAdditionalDriverBadgesDisabled() {
      return this.loading || this.isConfirmDisabled;
    },
    isFormDisabled() {
      return this.isConfirmDisabled || this.loading || this.isValidating;
    },
    buttonText() {
      return this.useCarpooling
        ? this.$t('carsharing_personal.btn_text_continue')
        : this.$t('carsharing_personal.confirm');
    },
    selectedInsurance: {
      get() {
        return this.reservation.insurance;
      },

      set(newInsurance) {
        this.reservation.insurance = newInsurance;
      },
    },
    upgradedInsurance() {
      const uuid = get(this, 'selectedInsurance.uuid');

      const upsellInsurances = get(this, 'bookingSummary.insurance.upsell_insurances');

      return find(upsellInsurances, { uuid });
    },
    businessComment: {
      get() {
        return this.reservation.comment;
      },
      set(newComment) {
        this.reservation.comment = newComment;
      },
    },
    useCarpooling: {
      get() {
        return this.reservation.useCarpooling;
      },
      set(val) {
        this.reservation.useCarpooling = val;
      },
    },

    isBookingBehalfAndEmployeePays() {
      return this.showPaymentMethod && this.isBookingOnBehalf;
    },
    showAdditionalPassengers() {
      return this.isBusinessProfileActive
        && get(this, 'reservation.operator.configuration.allow_passengers')
        && !this.isRecurringBooking;
    },
    showAdditionalDrivers() {
      const isDedicatedFleet = !get(this, 'reservation.location.isOpen');

      return (this.isBusinessProfileActive || isDedicatedFleet)
        && this.companyAllowsAdditionalDriver
        && !this.isRecurringBooking;
    },

    freeSeats() {
      const MAX_PASSENGERS = isEmpty(this.bookingSummary)
        ? get(this, 'reservation.vehicleCategory.default_seats', 1) - 1
        : get(this, 'bookingSummary.booking.free_seats', 0);

      const freeSeats = MAX_PASSENGERS - this.additionalPassengersCount - this.additionalDriversCount;
      return freeSeats < 0 ? 0 : freeSeats;
    },

    isAdditionalVehicleUserDisabled() {
      return this.freeSeats <= 0;
    },

    isOneWayCompatible() {
      const locationHasOneWay = get(this, 'reservation.location.one_way_allowed', false);
      const vehicleCategoryHasOneWay = get(this, 'reservation.vehicleCategory.one_way_allowed', false);
      const operatorHasOneWay = get(this, 'reservation.operator.configuration.has_one_way', false);

      return operatorHasOneWay && locationHasOneWay && vehicleCategoryHasOneWay;
    },
  },

  watch: {
    isAdditionalVehicleUserDisabled(value) {
      if (!isBoolean(value)) {
        return;
      }

      this.useCarpooling = value
        ? false
        : this.useCarpooling;
    },
  },

  async created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.appName = config.data.appName;
    this.requestCode = getRequestCode();

    this.ADDITIONAL_PASSENGERS_FILTER = ADDITIONAL_PASSENGERS_FILTER;
    this.ADDITIONAL_DRIVERS_FILTER = ADDITIONAL_DRIVERS_FILTER;
    this.EVENTS = COMPONENT_EVENTS;

    const dlBlockModalTexts = {
      title: this.$t('modal.driver_license_expired_date_validation.title'),
      description: this.$t('modal.driver_license_expired_date_validation.content.body_text'),
    };

    this.drivingLicenseReviewBlockModal = {
      ...genericUserBlockArgs(this.$t),
      ...dlBlockModalTexts,
      primaryCallToAction: () => {
        this.feedbackModal.isOpen = false;
      },
    };

    this.drivingLicenseExpiredBlockModal = {
      ...genericUserBlockArgs(this.$t),
      ...dlBlockModalTexts,
      primaryCallToAction: () => {
        this.feedbackModal.isOpen = false;
        this.$router.push({
          name: accountSettingsRouteNames.drivingLicense,
          params: {
            openDrivingLicenseForm: true,
          },
        });
      },
    };

    this.selectedInsurance = get(this, 'bookingSummary.insurance.current_insurance');

    if (this.isBusinessProfileActive) {
      this.businessComment = '';
    }

    this.useCarpooling = this.reservation.useCarpooling;

    this.trackSegment({ name: SEGMENT_EVENTS.BOOKING_SUMMARY });
  },

  beforeDestroy() {
    this.eventHandler.$off(this.events.NEW_PAYMENT_METHOD_CREATED);
  },

  methods: {
    isLongDistance,
    algoliaFilterForAdditionalDrivers,
    getDriverBadgeColor,
    get,
    toBoolean,
    map,
    size,

    ...mapActions(VehicleUserNamespace, {
      validateDrivers: VehicleUserActions.validateDrivers,
    }),

    // TODO: Temp fix, use the same store: https://europcarmobility.atlassian.net/browse/CBF-882
    ...mapMutations(
      BookingCostAllocationModule.NAMESPACE,
      {
        setCurrentCostAllocations:
          CostAllocationDataModule.MUTATIONS.setCurrentCostAllcation,
      },
    ),
    ...mapMutations(
      VehicleUserNamespace,
      {
        addVehicleUser: VehicleUserMutations.add,
        removeVehicleUser: VehicleUserMutations.remove,
        setInvalidVehicleUserDrivers: VehicleUserMutations.setInvalidDrivers,
      },
    ),

    ...mapMutations(BookingModule.nameSpace, {
      setBooking: BookingModule.MUTATIONS.SET_RESERVATION,
    }),

    onAddPassenger(text) {
      this.addVehicleUser({
        ...this.ADDITIONAL_PASSENGERS_FILTER,
        data: text,
      });
    },

    onAddDriver(driver) {
      if (!this.hasVehicleUserDriver(driver)) {
        this.addVehicleUser({
          ...this.ADDITIONAL_DRIVERS_FILTER,
          data: driver,
        });
      }
    },

    onRemovePassenger(passenger) {
      this.removeVehicleUser(passenger);
    },

    onRemoveDriver(driver) {
      this.removeVehicleUser(driver);
      const invalidDrivers = reject(this.invalidVehicleUserDrivers, ['email', driver.data]);
      this.setInvalidVehicleUserDrivers(invalidDrivers);
    },

    onChangeInsurance(selectedInsurance = {}) {
      this.trackSegment({
        name: SEGMENT_EVENTS.INSURANCE_SELECTION,
        data: {
          [SEGMENT_PARAM_NAMES.TYPE]: selectedInsurance.internal_name,
        },
      });
      this.selectedInsurance = selectedInsurance;
    },

    scrollToInput(inputHeight) {
      const topNavBar = $('.phNavbar');
      const navBarHeight = topNavBar[0].clientHeight;
      scrollTo(inputHeight - navBarHeight);
    },

    handleFormValidationErrors(validators) {
      const failedInputs = filter(validators, ['isValid', false]);
      if (failedInputs.length) {
        const firstFailedInput = reduce(failedInputs, (higher, input) => Math.max(higher, get(input, 'component.$el.offsetTop')), 0);
        const requiredFieldMissing = find(failedInputs, input => has(input.results, 'isRequired'));
        if (requiredFieldMissing.length) {
          this.notifyError({
            text: this.$t('carsharing_personal.error.missing_required_field'),
            textAction: this.$t('buttons.ok'),
          });
        }
        this.scrollToInput(firstFailedInput);
      }
    },

    validatePaymentMethod() {
      return this.isPaymentReady;
    },

    async validateAdditionalDrivers() {
      try {
        const driversEmail = map(this.additionalDriversFiltered, 'data');

        await this.validateDrivers({
          companyUuid: this.companyUuid,
          emails: driversEmail,
        });

        if (this.invalidVehicleUserDrivers.length) {
          const inputHeight = get(this, '$refs.additionalDriverInput.$el.offsetTop');
          this.scrollToInput(inputHeight);
        }

        return isEmpty(this.invalidVehicleUserDrivers);
      } catch (error) {
        this.notifyError({
          text: get(error, 'response.data.message', 'Error!'),
          textAction: this.$t('buttons.ok'),
        });

        return false;
      }
    },

    validateDrivingLicenseReview() {
      let DLExpirationDate = moment(
        get(this, 'reservation.location.userDocuments.next_driving_license_review_date'),
      );

      // If DL review expiration date is null/undefined for any case
      // we force it to fail using yesterday
      // "next_driving_license_review_date" just could be Nil when
      // user never has validated his DL review
      DLExpirationDate = DLExpirationDate.isValid() ? DLExpirationDate : moment().subtract(1, 'days');
      const isDLReviewExpireForBookingDate = DLExpirationDate.isBefore(this.bookingEnd);

      const isDrivingLicenseReviewAutorenewed
          = get(this, 'reservation.operator.configuration.allow_automatic_dl_review_renewal');

      if (!isDrivingLicenseReviewAutorenewed && isDLReviewExpireForBookingDate) {
        this.feedbackModal = { ...this.drivingLicenseReviewBlockModal, isOpen: true };
        return false;
      }

      return true;
    },

    validateDrivingLicenseOpen() {
      const dlExpiration = get(this, 'getUserDrivingLicense.expiration');
      const isDlExpired = dlExpiration && this.bookingEnd.isAfter(moment(dlExpiration, DATE_FORMAT.date));

      if (dlExpiration && isDlExpired) {
        this.feedbackModal = { ...this.drivingLicenseExpiredBlockModal, isOpen: true };
        return false;
      }

      return true;
    },

    async validateConfigs() {
      const requiresDrivingLicenseValidation
        = get(this, 'reservation.location.csOperatorRequirements.requires_driving_license_validation');

      const isOpenLocation = get(this, 'reservation.location.is_open');

      const validations = concat(
        this.validatePaymentMethod,
        this.showAdditionalDrivers ? this.validateAdditionalDrivers : [],
        requiresDrivingLicenseValidation ? this.validateDrivingLicenseReview : [],
        isOpenLocation ? this.validateDrivingLicenseOpen : [],
      );

      return reduce(validations, async (acc, validator) => await validator() && acc, true);
    },

    async submitReservation({ isValid, validationManagers }, event = {}) {
      this.isValidating = true;

      if (!isValid) {
        await this.handleFormValidationErrors(validationManagers);
        this.isValidating = false;
        return;
      }

      const configValidationsValid = await this.validateConfigs();
      this.isValidating = false;

      if (configValidationsValid) {
        this.createReservation(event);
      }
    },

    createReservation() {
      if (this.useCarpooling) {
        this.$router.push({ name: BookingRoutesNames.carpooling });
      } else if (get(this, 'reservation.isPreBooking')) {
        this.$emit('create-booking-request');
      } else {
        this.$emit('create-booking');
      }
    },

    updateBookingCost(costAllocation) {
      this.bookingCostAllocations = addCleanedCost(this.bookingCostAllocations, costAllocation);
    },
    showInsuranceDetails() {
      this.eventHandler.$emit(
        this.events.OPEN_INSURANCE_MORE_INFO_MODAL,
      );
    },
  },
};

</script>
