<template>
  <article
    v-if="vehicleCategory.tariff"
    v-openVehicleDetails="{
      element: slideToggleClass,
      callback: clickMarker,
      disable: expanded
    }"
    class="Vehicle p-3 mb-3 emobg-background-color-white emobg-shadow-s"
  >
    <!-- Image about vehicle -->
    <figure class="Vehicle_imageWrapper">
      <img
        :src="vehicleCategory.category_image"
        alt="Vehicle image"
        width="175px"
        class="Vehicle_image mb-2"
      >
    </figure>

    <!-- Info about vehicle -->
    <div class="Vehicle_dataWrapper">
      <div class="Vehicle_data">
        <div class="Vehicle_model mb-1">
          {{ vehicleCategory.name }}
        </div>
      </div>

      <div
        v-if="isVehicleOneWayCompatible"
        class="d-flex"
      >
        <ui-icon
          :icon="ICONS.carPickUp"
          :size="ICONS_SIZES.small"
        />
        <p class="ml-2 emobg-font-small emobg-font-weight-bold">
          {{ $t('BookingView.VehicleCategory.has_one_way') }}
        </p>
      </div>

      <!-- Links -->
      <div
        v-if="isUnpriced"
        class="Vehicle__links"
      >
        <!-- Show or hide details in Home / My bookings page -->
        <span class="emobg-font-small mr-1">
          {{ showHideDetailsLabel }}
        </span>
      </div>

      <!-- Info about the vehicle tariff -->
      <div
        v-else
        class="Vehicle__text"
      >
        <div class="Vehicle_price-bold">
          {{ isLongDistance(get(currentBookingType, 'code'))
            ? `${get(vehicleCategory, 'tariff.daily_price')}/d`
            : `${get(vehicleCategory, 'tariff.hourly_price')}/h`
          }}
        </div>

        <div>
          <span class="Vehicle_price--small">
            + {{ vehicleCategory.tariff.km_price_ranges[0].price }}/{{ getVisitedMileageSymbol }}
          </span>
          <span
            v-if="vehicleCategory.tariff && vehicleCategory.tariff.free_mileage_text"
            class="Vehicle_price--small"
          >
            ({{ vehicleCategory.tariff.free_mileage_text }})
          </span>
        </div>
      </div>
    </div>

    <!-- Action button -->
    <div class="Vehicle__bookingWrapper">
      <ui-button
        v-if="hasPermissionToBook"
        v-bind="fetchButtonSpecs()"
        :disabled="!canBook"
        :loading="isButtonLoading"
        :size="SIZES.large"
        class="mb-3 mr-3"
        data-test-id="book_car"
        @clickbutton="booking"
      >
        {{ bookButtonText }}
      </ui-button>
    </div>

    <!-- Alert text for non authorized location (b. on behalf) -->
    <template v-if="showBookingOnBehalfAlert">
      <div class="my-2 ml-2 row">
        <ui-icon
          :color="COLORS.danger"
          :icon="ICONS.alertAloneFull"
          :size="ICONS_SIZES.small"
        />
        <span class="emobg-font-default ml-2">
          {{ bookingOnBehalfErrorMessage }}
        </span>
      </div>
    </template>

    <!-- Links -->
    <div
      v-if="!isUnpriced"
      class="Vehicle__links"
    >
      <!-- Show or hide details in Home / My bookings page -->
      <span class="emobg-font-small mr-1">
        {{ showHideDetailsLabel }}
      </span>
    </div>

    <div
      :class="['Vehicle_details mt-2 mb-1', slideToggleClass]"
      :style="expanded ? '' : 'display: none'"
    >
      <!-- Show models of the category -->
      <div
        v-if="size(vehicleCategory.models)"
        class="Vehicle_details_section mb-2"
      >
        <div class="Vehicle_details_header emobg-body-medium mb-1 pb-1">
          {{ $t('new_booking.booking_list.vehicle.vehicle_categories_models') }}
        </div>
        <div class="Vehicle_details_content flex-wrap">
          <div class="Vehicle_details_text w-100">
            <div class="mb-1">
              <div
                v-for="(model, index) in vehicleCategory.models"
                :key="index"
              >
                {{ model }}
              </div>
            </div>
          </div>
          <div class="Vehicle_details_text w-100">
            {{ $t('new_booking.booking_list.vehicle.vehicle_categories_assigned_automatically') }}
          </div>
        </div>
      </div>

      <!-- Pricing information -->
      <TariffInformationComponent
        v-if="!isUnpriced"
        :tariff="vehicleCategory.tariff"
        :mileage-unit="mileageUnit"
        :date-range="bookedTime"
      />
    </div>
  </article>
</template>
<script>
import get from 'lodash/get';
import size from 'lodash/size';
import head from 'lodash/head';
import filter from 'lodash/filter';

import { mapGetters } from 'vuex';
import { aclService } from '@emobg/access-utils';
import { DISTANCE_UNIT } from '@emobg/web-utils';

// Components
import TariffInformationComponent from '@/components/VehicleCard/components/TariffInformation/TariffInformationComponent';
// Composable
import { useAvailability } from '@/composable/Availability/useAvailability';
// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';
import CsOperatorMixin from '@/mixins/CSOperator';

// Services
import googleMapsLoader from '@/vue/managers/GoogleMapsLoader';

import { nameSpace as CSOperatorNameSpace } from '@/vue/stores/CSOperator/CSOperatorStore';

// Directives
import SlideToggle from '@/directives/SlideToggle';
// Constants

import { USER_STATUS } from '@/constants/userStatus.const';
import { CREATE_CS_LD_OWN_BOOKINGS } from '@/constants/permissions';
import { LOCATION_TYPES } from '@/constants/location.const';

import { isActivated, isPreregistered } from '@/helpers/user/status/userStatusHelper';

import { isLongDistance } from '@/helpers/booking/bookingHelpers';
import { useTheme } from '@/composable/Theme/useTheme';

const $ = require('jquery');

export default {
  name: 'VehicleCategory',

  components: {
    TariffInformationComponent,
  },

  directives: {
    openVehicleDetails: SlideToggle,
  },

  mixins: [
    EventHandlerMixin,
    CsOperatorMixin,
  ],

  props: {
    location: {
      type: Object,
      default: () => ({}),
    },
    vehicleCategory: {
      type: Object,
      default: () => ({}),
    },
    mapManager: {
      type: Object,
      default: () => ({}),
    },
    expanded: {
      type: Boolean,
    },
    bookedTime: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { getSelectedEmployee, isBookingOnBehalf, currentBookingType } = useAvailability();
    const { fetchButtonSpecs } = useTheme();
    return { getSelectedEmployee, isBookingOnBehalf, currentBookingType, fetchButtonSpecs };
  },

  data() {
    return {
      slideToggleClass: `js-slideToggle-${(Math.random() * 10000).toFixed(0)}`,
      details: false,
      isButtonLoading: false,
    };
  },
  computed: {
    ...mapGetters(CSOperatorNameSpace, ['findCSOperatorByUUID']),

    canBook() {
      const vehiclesAvailables = get(this, 'location.show_vehicle_availability', true) && !this.isAvailabilitySuggestionsEnabled
        ? get(this, 'vehicleCategory.has_available_vehicles')
        : true;

      const employeeOpenFleetStatus = get(this.getSelectedEmployee, 'open_fleet_status', USER_STATUS.deactivated);
      const employeeCanBookLocationType = get(this, 'location.open', true)
        ? isActivated(employeeOpenFleetStatus)
        : true;

      const bookingOnBehalfConditions = this.isBookingOnBehalf
        ? !this.employeeSelectedHasInvalidDocuments && employeeCanBookLocationType
        : true;

      return vehiclesAvailables && bookingOnBehalfConditions;
    },

    availabilitySuggestionsAvailable() {
      return get(this.csOperatorConfig, 'stack_availability_enabled')
        && get(this, 'location.type') === LOCATION_TYPES.stack;
    },

    isAvailabilitySuggestionsEnabled() {
      return this.availabilitySuggestionsAvailable && !get(this, 'vehicleCategory.has_available_vehicles');
    },

    price() {
      const { state } = this.$store;
      return get(state, 'Booking.bookingSummary.price.total', 0);
    },

    showHideDetailsLabel() {
      if (this.details) {
        return this.$t('new_booking.booking_list.vehicle.hide_availability_details');
      }

      return this.$t('new_booking.booking_list.vehicle.availability_details');
    },
    isUnpriced() {
      return get(this, 'vehicleCategory.tariff.is_unpriced_tariff');
    },

    employeeSelectedHasInvalidDocuments() {
      return get(this, 'location.user_documents.hasInvalidDocuments');
    },

    employeeSelectedStatus() {
      return get(this, 'getSelectedEmployee.user_status', USER_STATUS.deactivated);
    },

    showBookingOnBehalfAlert() {
      return this.isBookingOnBehalf && (
        this.employeeSelectedHasInvalidDocuments || isPreregistered(this.employeeSelectedStatus)
      );
    },

    bookingOnBehalfErrorMessage() {
      return isPreregistered(this.employeeSelectedStatus)
        ? this.$t('new_booking.booking_list.booking_on_behalf.employee_is_pre_registered.vehicle.info')
        : this.$t('new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.vehicle.info');
    },

    bookButtonText() {
      const textOptions = [
        {
          condition: this.isBookingOnBehalf && this.employeeSelectedHasInvalidDocuments,
          translationKey: 'new_booking.booking_list.booking_on_behalf.employee_has_invalid_documents.vehicle.action_button',
        },
        {
          condition: this.isAvailabilitySuggestionsEnabled,
          translationKey: 'new_booking.booking_list.vehicle.check_availability',
        },
        {
          condition: !this.canBook,
          translationKey: 'new_booking.booking_list.vehicle.not_available',
        },
        {
          condition: true,
          translationKey: 'new_booking.booking_list.vehicle.book_car',
        },
      ];
      const { translationKey } = head(filter(textOptions, 'condition'));

      return this.$t(translationKey);
    },

    hasPermissionToBook() {
      return this.isBookingOnBehalf || aclService.hasPermissions(CREATE_CS_LD_OWN_BOOKINGS);
    },

    csOperatorConfig() {
      return get(this.findCSOperatorByUUID(get(this, 'location.cs_operator_uuid')), 'configuration');
    },

    mileageUnit() {
      return get(this, 'csOperatorConfig.mileage_unit', DISTANCE_UNIT.kilometers);
    },

    isVehicleOneWayCompatible() {
      const locationHasOneWay = get(this, 'location.one_way_allowed', false);
      const vehicleCategoryHasOneWay = get(this, 'vehicleCategory.one_way_allowed', false);
      const operatorHasOneWay = get(this, 'csOperatorConfig.has_one_way', false);

      return operatorHasOneWay && locationHasOneWay && vehicleCategoryHasOneWay;
    },
  },
  methods: {
    get,
    size,
    isLongDistance,
    clickMarker() {
      this.details = !!$(`.${this.slideToggleClass}:visible`).length;

      if (this.details) {
        const google = googleMapsLoader.getInstance();

        const list = this.mapManager.getMarkerList();

        const myMarker
            = list[`location_${this.location.uuid}`]
            || (this.currentBooking && list[`location_${this.currentBooking.uuid}`]);

        if (myMarker && this.mapManager.getLastMarkerSelected() !== myMarker) {
          this.mapManager.showMarker(myMarker);
          google.maps.event.trigger(myMarker, 'click');
        }
      }
    },

    booking() {
      this.isButtonLoading = true;

      this.eventHandler.$emit(this.events.GO_BOOKING_CONFIRM, {
        vehicleCategory: this.vehicleCategory,
        location: this.location,
        isAvailabilitySuggestionsEnabled: this.isAvailabilitySuggestionsEnabled,
        toggleBtnLoading: () => {
          this.isButtonLoading = !this.isButtonLoading;
        },
      });
    },

  },
};
</script>
